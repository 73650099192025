import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About This Game</h1>
      <p>This game is designed to ... [add more about your game]</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default About;